.section {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1023px) {
    .section {
    width: 80%;
    margin: 0 11% 0 9%;
    }
  }
  @media screen and (max-width: 767px) {
    .section {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 428px) {
    .section {
      width: 86%;
      margin: auto 7%;
    }
  }