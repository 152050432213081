.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content__title {
  margin: auto 0 3.5rem 0;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.8rem;
}
.content__text {
  margin: 0 0 8% 0;
  font-size: 1.5rem;
  line-height: 2.2rem;
}
.content__input-wrapper {
  display: flex;
  position: relative;
}
.content__form-question {
  font-size: 2rem;
  line-height: 3.8rem;
  margin: 0 5% 0 0;
  font-weight: 400;
}
.content__form-input {
  min-height: 4.5rem;
  border-radius: 100px;
  width: 100%;
  text-align: center;
  outline: none;
  border: 0;
  font-size: 2rem;
  box-shadow: 0px 20px 60px #c9d8ff;
  padding: 1rem 1.5rem;
  transition: box-shadow 0.2s linear;
  -webkit-appearance: none;
  margin-bottom: 1rem;
}
.content__form-input:hover {
  box-shadow: 0px 20px 60px #96b3ff;
}
.content__form-input:focus {
  box-shadow: 0px 20px 60px #96b3ff;
}

.content__form-input-container {
  display: flex;
  height: 8rem;
  flex-direction: column;
  max-width: 26%;
  width: 100%;
  border-radius: 100px;
  position: relative;
  align-items: center;
}

.content__form-input-label {
  position: absolute;
  font-size: 1.7rem;
  top: 18%;
  left: 20%;
  transition: all 0.2s linear;
  pointer-events: none;
  color: gray;
}
.content__form-input-label_active {
  top: -8%;
  font-size: 1.5rem;
}
.content__form-input-error {
  text-align: center;
}

.next-button__container {
  display: flex;
  margin: auto 0 0 auto;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
}
.next-button {
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: center;
  height: 120px;
  border: 2px solid #fff;
  border-radius: 100px;
  position: relative;
  background-color: transparent;
}

.next-button__overlay {
  display: flex;
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: transparent;
  border-radius: 100px;
  border: 2px solid transparent;
  z-index: 0;
  transition: box-shadow 0.2s linear;
}
.next-button:hover .next-button__overlay {
  background: radial-gradient(#f5ae9d, transparent);
  background-position-y: bottom;
  border: 2px solid #fff;
  box-shadow: 0px 20px 60px #f5ae9d;
}
.next-button__icon {
  width: 1.5rem;
  z-index: 1;
}
.next-button:active .next-button__overlay {
  background: #fff;
  box-shadow: none;
}
.next-button:active {
  background-color: #fff;
}
.next-button:active .next-button__icon {
  filter: invert(28%) sepia(31%) saturate(497%) hue-rotate(177deg)
    brightness(54%) contrast(97%);
}
@media screen and (max-width: 1023px) {
  .content__form-input-container {
    max-width: 40%;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 428px) {
  .next-button {
    width: 6rem;
    height: 6rem;
  }
}
@media screen and (max-width: 428px) {
  .next-button__container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 428px) {
  .next-button__overlay {
    width: 6rem;
    height: 6rem;
  }
}
@media screen and (max-width: 428px) {
  .content__title {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 90%;
  }
}
@media screen and (max-width: 428px) {
  .content__text {
    font-size: 2rem;
    line-height: 3rem;
    padding: 0;
  }
}
@media screen and (max-width: 428px) {
  .content__form-question {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
@media screen and (max-width: 428px) {
  .content__form-input-container {
    max-width: 100%;
    margin: 2rem 0;
  }
}
@media screen and (max-width: 428px) {
  .content__form-input {
    margin-bottom: 1rem;
    box-shadow: 0px 20px 60px #96b3ff;
    -webkit-box-shadow: 0px 20px 60px #96b3ff;
  }
}
@media screen and (max-width: 428px) {
  .content {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
}
@media screen and (max-width: 428px) {
  .next-button__overlay {
    background: radial-gradient(#f5ae9d, transparent);
    background-position-y: bottom;
    border: 2px solid #fff;
    box-shadow: 0px 20px 60px #f5ae9d;
  }
}
@media screen and (max-width: 428px) {
  .back-button_blue {
    box-shadow: 0px 20px 60px #96b3ff;
    background: radial-gradient(#96b3ff, transparent);
  }
}
@media screen and (max-width: 428px) {
  .content__form-input-error {
    font-size: 1.4rem;
  }
}
