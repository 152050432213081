.greetings .content__text {
    padding-right: 180px;
    margin-bottom: 30px;
    font-weight: normal;
}

.content__input-wrapper_type_greetings {
    flex-direction: column;
}
@media screen and (max-width: 428px) {
    .greetings .content__text {
       padding: 0;
    }
  }