.contacts {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: auto;
}

.contacts__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.contacts__wrapper {
    width: 60%;
}
.contacts__item-photo {
    width: 180px;
    border-radius: 20px;
    filter: grayscale();
    transition: all .4s linear;
}

.contacts__item .contacts__item-photo:hover {
    transform: scale(1.03);
}

.contacts__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin: 2rem 0 .25rem 0;
}

.contacts__subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
}

.contacts__link {
    text-decoration: none;
    font-size: 24px;
    color: #3B4C66;
    display: flex;
    align-items: center;
    margin-top: .8rem;
}

.contacts__link-icon {
    width: 1.2rem;
    margin-right: 10px;
}

.contacts__links {
    display: flex;
}

.contacts__address {
    max-width: 220px;
}
.contacts__text {
    margin: 3.5rem 0 0 0;
}
.contacts__adress-text {
    margin-top: 2.5rem;
}
.next-button__container:hover .back-button_blue {
    background: radial-gradient(#96b3ff, transparent);
    background-position-y: bottom;
    border: 2px solid #fff;
    box-shadow: 0px 20px 60px #96b3ff;
}

@media screen and (max-width: 1023px) {
    .contacts {
      flex-direction: column;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__address {
      max-width: 100%;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__title {
      margin-top: 1.6rem;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__address .content__title {
      margin: 5rem 0 0 0;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__adress-text  {
      margin: 1.5rem 0 0 0;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__main-title  {
     margin-bottom: 2.8rem;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__content  {
     max-width: 100%;
  }
}
@media screen and (max-width: 1023px) and (max-height: 700px) {
    .contacts__content  {
     max-width: 80%;
  }
}

@media screen and (max-width: 1023px) {
    .contacts__item-photo  {
     width: 150px;
  }
}
@media screen and (max-width: 1023px) {
    .contacts__item  {
     margin-right: 2rem;
  }
}
@media screen and (max-height: 700px) {
    .contacts__text {
        margin-top: 1rem;
    }
}
@media screen and (max-height: 700px) {
    .contacts__main-title {
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 428px) {
    .contacts__wrapper {
        width: 100%;
    }
}
@media screen and (max-width: 428px) {
    .contacts__content {
        flex-direction: column;
    }
}
@media screen and (max-width: 428px) {
    .contacts__item {
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 428px) {
    .contacts__item-photo {
        width: 90px;
        margin: 0 2rem 2rem 0;
    }
}
@media screen and (max-width: 428px) {
    .contacts__item-text-wrapper {
        display: flex;
        flex-direction: column;
     justify-content: flex-start;
    }
}
@media screen and (max-width: 428px) {
    .contacts__title {
        margin-top: .5rem;
    }
}
@media screen and (max-width: 428px) {
    .contacts__link-icon {
        width: 2rem;
    }
}
@media screen and (max-width: 428px) {
    .contacts__text {
        margin-top: 1.5rem;
    }
}
@media screen and (max-width: 428px) {
    .contacts__address .content__title {
        margin-top: 1.5rem;
    }
}

