.checkbox {
    contain: content;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    color: rgba(59, 76, 102, .7);
    font-size: 18px;
    line-height: 22px;
    margin: 0 40px 0 0;
    border-radius: 68px;
    height: 52px;
    box-shadow: 0px 20px 60px #FFC3B0;
    transition: box-shadow 0.2s linear;
    -webkit-transition: box-shadow 0.2s linear;
    overflow: hidden;
}
.checkbox:hover {
    box-shadow: 0px 20px 60px #F5AE9D;
}
.checkbox__label {
    cursor: pointer;
}

.checkbox__input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    visibility: hidden;
}

.checkbox__box {
    align-items: center;
    background: #FFFFFF;
    border-radius: 68px;
    display: flex;
    height: 52px;
    justify-content: center;
    letter-spacing: -0.01em;
    overflow: hidden;
    padding: 0 22px;
    position: relative;
    user-select: none;
}

.checkbox__title {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.checkbox__ripple {
    border-radius: 68px;
    display: block;
    inset: 0;
    overflow: hidden;
    position: absolute;
    transform: translateZ(0px);
    -webkit-transform: translateZ(0px);
    z-index: 1;
    box-shadow: none;
}



.checkbox__ripple-span {
    background: #F5AE9D;
    border-radius: 68px;
    -webkit-border-radius: 50%;
    display: block;
    height: 100%;
    -webkit-height: 100%;
    transform: translateY(100%) translateZ(0px);
    -webkit-transform: translateY(100%) translateZ(0px);
    transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    width: 100%;
}

.checkbox__input:checked~.checkbox__box .checkbox__ripple-span {
    border-radius: 0;
    transform: translateY(0) translateZ(0);
    -webkit-transform: translateY(0) translateZ(0);
}

.checkbox__input:checked~.checkbox__box .checkbox__title-span {
    color: #fff;
    z-index: 2;
}
  @media screen and (max-width: 428px) {
    .checkbox {
       width: 100%;
       margin: .5rem 0 .5rem 0;
       box-shadow: 0px 20px 60px #F5AE9D;
    }
}