.mail .content__text{
  margin: 0 0 3rem 0;
  padding-right: 200px;
}
.mail .content__text:first-of-type {
  margin: auto 0 3.5rem 0;
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: normal;
}
.mail .next-button__container {
  margin-top: auto;
}
.content__link {
    display: inline-block;
    text-decoration: none;
    color: #000;
}

.content__link-icon {
    width: 20px;
    vertical-align: middle;
  }
  @media screen and (max-width: 428px) {
    .mail .content__text {
       padding: 0;
    }
  }