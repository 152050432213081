.page {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-size: 110% 110%;
    font-family: "Neue Haas Unica", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3b4c66;
    overflow: hidden;
    -webkit-appearance:none;
  }
  @media screen and (max-width: 428px) {
    .page {
        overflow-y: scroll;
        min-height: 100vh;
        height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       }
}