.header {
  display: flex;
  height: 10%;
  padding: 0 9vw 0 6vw;
  justify-content: space-between;
}
.header__logo-wrapper {
  display: flex;
  align-items: center;
  margin: 5.5vh 0 0 1rem;
  position: relative;
  z-index: 5;
  text-decoration: none;
  color: inherit;
}
.header__logo {
  height: 20px;
}
.header__logo-text {
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 0 15px;
}
.header__langs-container {
  display: flex;
  margin-top: auto;
  position: relative;
  height: fit-content;
  cursor: pointer;
}
.header__current-lang {
  font-size: 16px;
  line-height: 24px;
  padding-right: 10px;
}
.header__button-lang {
  font-size: 16px;
  line-height: 24px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 1rem;
}
.header__lang-wrapper {
  display: flex;
  height: 27px;
  border-bottom: 2px solid transparent;
}
.header__lang-wrapper-icon {
  width: 20px;
}
.header__buttons-container {
  width: 0;
  height: 0;
  overflow: hidden;
}
.header__buttons-container_visible {
  width: fit-content;
  height: fit-content;
}
.link_animated {
  background-image: linear-gradient(
    transparent 0%,
    transparent 90%,
    #3b4c66 90%,
    #3b4c66 100%
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position-x: right;
  transition: background-size 0.3s;
}
.header .link_animated {
  background-image: linear-gradient(
    transparent 0%,
    transparent 95%,
    #3b4c66 95%,
    #3b4c66 100%
  );
}
.link_animated:hover {
  background-size: 100% 100%;
  background-position-x: left;
}
.hidden {
  opacity: 0;
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 0 5vw 0 3vw;
  }
}
@media screen and (max-width: 428px) {
  .header {
    height: 7rem;
    position: fixed;
    width: 100%;
    z-index: 5;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 428px) {
  .header__logo-wrapper {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 428px) {
  .header__langs-container {
    display: none;
  }
}
@media screen and (max-width: 428px) {
  .header .link_animated {
    background-image: none;
  }
}

@media screen and (max-width: 428px) {
  .link_animated {
    background-image: linear-gradient(
      transparent 0%,
      transparent 95%,
      #000 95%,
      #000 100%
    );
    background-size: 100% 100%;
    background-position-x: left;
  }
}
.header_white {
  background-color: rgba(255, 255, 255, 0.9);
}
