.condition {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.condition .content__title {
    margin-bottom: 0;
}
.condition .content__text {
    margin: 3rem 0 4rem 0;
}

.condition .content__input-wrapper {
    margin-top: 2rem;
}
.condition__checkbox-wrapper {
    max-height: 328px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
     align-items: center;
}
@media screen and (max-width: 1023px) {
    .condition .content__text {
      margin: 3rem 0 1rem 0;
    }
  }
  @media screen and (max-width: 1023px) {
    .content__input-wrapper  {
     flex-direction: column;
  }
}
@media screen and (max-width: 1023px) {
  .content__form-question  {
   margin-bottom: 1rem;
}
}


@media screen and (max-width: 428px) {
  .condition__checkbox-wrapper {
     max-height: fit-content;
  }
}
@media screen and (max-width: 428px) {
  .condition {
     justify-content: space-between;
  }
}
@media screen and (max-width: 428px) {
  .condition .content__title {
     margin-top: 10%;
  }
}
@media screen and (max-width: 428px) {
  .condition__form {
     height: 48%;
  }
}
@media screen and (max-width: 428px) {
  .condition .next-button__container {
     margin-top: 0;
  }
}
