@import url(../src/vendor/normalize.css);
@import url(../src/fonts/NeueHaasUnica-Regular/style.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
a:visited {
  text-decoration: none;
  outline: none;
}
* {
  box-sizing: border-box;
  outline: none;
}
@media screen and (max-width: 1023px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-height: 700px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 428px) {
  html {
    font-size: 10px;
  }
}
