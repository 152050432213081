.footer {
    margin-top: 2rem;
    display: flex;
    height: 10%;
    padding: 0 10%;
  }
  .footer__link {
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
    align-items: center;
    color: #3b4c66;
    font-size: 14px;
    line-height: 20px;
    height: 1.5rem;
    margin: 0 4.3rem 0 0;
    padding-bottom: 5px;
  }
  .footer__link-icon {
    width: 18px;
    height: 18px;
  }
  .footer__link-text {
    margin: 0 0 0 0.7rem;
  }
  .footer__link_active {
    color: #fff;
  }
  .hidden {
    opacity: 0;
  }
  @media screen and (max-width: 428px) {
    .footer {
      display: none;
    }
  }