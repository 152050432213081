.popup {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 0;
  padding: 0;
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
  display:flex;
  overflow: hidden;
  transition: all .3s linear;
}
.popup__link {
    display: flex;
    font-size: 2rem;
    line-height: 3rem;
    text-decoration: none;
    color: #3b4c66;
    align-items: center;
    margin-left: 1rem;
}
.popup__navbar {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
}
.popup__link-text {
    margin-left: 2.4rem;
}
.popup__link-icon {
    width: 2rem;
}
.popup__languages-container {
    display: flex;
    margin-left: 10%;
    justify-content: space-between;
}
.popup__logo-wrapper {
    display: flex;
    align-items: center;
}
.popup__language-select {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
}
.popup__button-lang {
    font-size: 2rem;
    border: none;
    background-color: transparent;
    color:#3b4c66;
}
.popup__button {
    display: none;
    width: 5rem;
    height: 5rem;
    border: none;
    position: absolute;
    right: 1rem;
    top: 3rem;
    z-index: 5;
    background-color: transparent;
}
.popup__button::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 3rem;
    top: 0;
    left: 0;
    background-color: #3b4c66;
    transition: .3s;
}
.popup__button::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 3rem;
    top: 1rem;
    left: 0;
    background-color: #3b4c66;
    transition: .2s;
}
.popup__button_active::before {
    transform: rotate(-45deg);
}
.popup__button_active::after {
    transform: rotate(45deg);
    top: 0;
}
.popup_active {
    height: 100vh;
    padding: 0 25% 30% 0;
}
@media screen and (max-width: 428px) {
    .popup__button {
      display: block;
    }
  }
  .popup__header {margin-top: 5%;}
  .popup__button-lang_active {
      text-decoration: underline;
  }