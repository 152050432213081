.video {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.video-wrapper {
    display: flex;
    margin-top: auto;
}
.iframe {
    width: 405px;
    height: 210px;
    border-radius: 20px;
    border: none;
    margin: 2rem 3.2rem 0 0px;
}

.video .content__text {
    margin-bottom: 20px;
}
.video__content-wrapper {
    padding-top: 10rem;
   margin-top: auto;
}
.video .next-button__container {
    align-items: center;
  }
.next-button__container .content__text {
    margin: 0 20px 0 0;
}
.back-button {
   width: 92px;
   height: 92px;
  }

  @media screen and (max-width: 1023px) {
    .video-wrapper {
      flex-direction: column;
  }
}
@media screen and (max-width: 1023px) {
    .video__content-wrapper {
      padding-top: 3rem;
  }
}
@media screen and (max-width: 1023px) {
    .video__video-wrapper .content__title {
      margin-bottom: 0;
  }
}
@media screen and (max-width: 1023px) {
    .back-button {
        width: 72px;
        height: 72px;
       }
}
@media screen and (max-width: 428px) {
    .back-button {
        width: 52px;
        height: 52px;
       }
}
@media screen and (max-width: 428px) {
        .iframe {
            width: 95%;
            height: auto;
            border-radius: 20px;
            border: none;
            margin: 2rem 3.2rem 0 0px;
        }
}
